<template>
  <footer class="footer" :class="{ [`footer-${type}`]: type }" :data-background-color="backgroundColor">
<div class="waves">
  <div class="wave" id="wave1"></div>  
  <div class="wave" id="wave2"></div>
</div>

    <div class="footer-contenedor">
<!--       <ul class="contenedor-opciones">
        <li class="opciones"><a href="#">Nosotros</a></li>
        <li class="opciones"><a href="#">Proyectos</a></li>
        <li class="opciones"><a href="#">Contáctenos</a></li>
        <li class="opciones"><a href="#">Reglamentos</a></li>
      </ul>  --> 
       
      <br>
    </div>
     <p class="contenedor-copyright">2024 | © Derechos reservados - Alianza Mar y Tierra</p>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.wave {
  position:absolute;
  top: -68px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("../../public/img/mar.svg");
  background-size: 990px 100px;
}

.wave#wave1{
z-index: 1000;
opacity: 1;
bottom: 0;
animation: animationWave 10s linear infinite;
}

.wave#wave2{
z-index: 999;
opacity: 0.7;
bottom: 20px;
animation: animationWave2 5s linear infinite;
}

@keyframes animationWave {
  0%{
    background-position: 990px;
  }
100%{
  background-position-x: 0px;
}
}

@keyframes animationWave2 {
  0%{
    background-position: 0px;
  }
100%{
  background-position-x: 990px;
}
}
</style>
