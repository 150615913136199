<template>
  <div>
    <div class="page-header">
      <parallax class="page-header-image" style="background-image:url('img/fondo.webp')">
      </parallax>
      <div class="container">
        <div class="content-right brand">
          <!--  <img class="n-logo" src="img/logo-alianza.webp" alt="" /> -->
          <h1 class="h1-seo">Alianza Mar y Tierra</h1>
          <h3 class="h3-phrase">Juntos podemos conservar y restaurar la vida marítima y terrestre.</h3>

          <div class="content-right__btn">
            <!-- <a class="btn-neutral btn-round btn-simple">QUIERO UNIRME</a> -->
            <!-- <a class="btn-primary btn-round btn-color">DONAR</a> -->
          </div>
        </div>
      </div>
    </div>
    <div id="QuienesSomos" class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">¿Quienes somos?</h2>
            <h5 class="description">
              Somos una asociación sin fines de lucro, que funciona como plataforma ciudadana de gestión, creación y
              ejecución de proyectos de conservación y restauración
              marítima y terrestre.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div class="image-container image-left"
                style="background-image: url('img/proyectos/limpiezaOcotal1.webp')">
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  Conformados por un grupo de vecinos de Ocotal y Playas del Coco movilizados por hacer un cambio en
                  nuestro entorno.
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div class="image-container" style="background-image: url('img/proyectos/limpiezaOcotal3.webp')"></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div class="image-container image-right"
                style="background-image: url('img/proyectos/limpiezaOcotal4.webp')"></div>
              <h3>
                Trabajamos en apoyar la recuperación natural de los arrecifes mediante el cultivo, la plantación y el
                monitoreo de corales.
              </h3>
              <p>
                Involucramos y empoderamos a la comunidad para que se una a nosotros para salvar los arrecifes de coral
                de nuestro planeta con programas limpieza de playas, restauración de corales y charlas educativas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="Proyectos" class="section">
      <div class="container container--center">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <h2 class="title">Proyectos</h2>
          <h5 class="description">
            Principalmente trabajamos en la restauración del arrecife marino. Inicialmente, capacitamos y colaboramos
            estrechamente con la comunidad local. Posteriormente, llevamos a cabo la siembra de coral, seguida de la
            instalación de estructuras, y nos ocupamos de la limpieza y el mantenimiento continuo del área.
          </h5>
        </div>
        <div class="section" id="carousel">
          <div class="container">
            <div class="row justify-content-center row--width">
              <div class="col-12">
                <el-carousel height="600px">
                  <el-carousel-item>
                    <img class="d-block" src="img/proyectos/AMYT2.webp" alt="First slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5></h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/proyectos/AMYT8.webp" alt="Second slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5></h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/proyectos/AMYT9.webp" alt="Third slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5></h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/proyectos/AMYT29.webp" alt="Third slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5></h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/proyectos/AMYT33.webp" alt="Third slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5></h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/proyectos/AMYT58.webp" alt="Third slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5></h5>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <img class="d-block" src="img/proyectos/AMYT81.webp" alt="Third slide" />
                    <div class="carousel-caption d-none d-md-block">
                      <h5></h5>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Nuestro equipo</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-3">
              <div class="team-player">
                <img src="img/knowUs/KnowUs1.jpeg" alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised know-us-image" />
                <h4 class="title know-us-title know-us-position">Elsiana Luna</h4>
                <p class="category text-primary">Ingeniera en Salud Ocupacional y Ambiente</p>
                <p class="description know-us-description">
                  Co-Fundadora y directora de Asociación Alianza Mar y Tierra.</p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img src="img/knowUs/KnowUs2.jpeg" alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised know-us-image" />
                <h4 class="title know-us-title know-us-position">Lucas Zañartu</h4>
                <p class="category text-primary">Licenciado en Comunicación Social y Audiovisual</p>
                <p class="description know-us-description">
                  Especializado en conservación marina y ciencias.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img src="img/knowUs/KnowUs3.jpeg" alt="Karina Villalobos"
                  class="rounded-circle img-fluid img-raised know-us-image" />
                <h4 class="title know-us-title know-us-position">Karina Villalobos</h4>
                <p class="category text-primary">Abogada y notaria pública</p>

                <p class="description know-us-description">
                  Ha sido buzo voluntaria en proyectos de restauración de corales.
                </p>
              </div>
            </div>
            <div class="col-md-3">
              <div class="team-player">
                <img src="img/knowUs/KnowUs4.jpeg" alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised know-us-image" />
                <h4 class="title know-us-title">Juliana Cambronero</h4>
                <p class="category text-primary know-us-position">Bióloga-Ecóloga</p>
                <p class="description know-us-description">
                  Instructora de buceo PADI, se ha especializado en conservación y manejo de corales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

    <div id="Contactenos" class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Contáctenos</h2>
<div>
  <a class="nav-link--styles" href="tel:50687378450">
    <i data-v-07f82564="" class="now-ui-icons tech_mobile"></i>
  <p data-v-07f82564="">Teléfono: +506 87378450</p>
</a>
<br>
<a class="nav-link--styles" href="mailto:alianzamarytierra@gmail.com">
    <i data-v-07f82564="" class="now-ui-icons ui-1_email-85"></i>
  <p data-v-07f82564="">Correo: alianzamarytierra@gmail.com</p>
</a>
<br>
<a class="nav-link--styles" href="https://www.google.com/maps/dir//Playa+Ocotal+Provincia+de+Guanacaste/@10.5460003,-85.7225189,10z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8f9e29aa7c6ef72b:0x22bfecb357583518!2m2!1d-85.7225189!2d10.5460003?entry=ttu" target="_blank"> 
    <i data-v-07f82564="" class="now-ui-icons location_pin"></i>
  <p data-v-07f82564="">Ubicación: Costa Rica, Guanacaste, Carrillo, Sardinal, Playa Ocotal.</p>
</a>  

</div>
      </div>
    </div>


    
  </div>
</template>
<script>
import { Parallax } from '@/components';
import { Carousel, CarouselItem } from 'element-ui';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  }
}; 
</script>
<style>
.content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  height: 80%;

}


.content-right__btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  gap: 20px;
}

.content-right__btn * {
  text-decoration: none !important;
}

.rounded-circle {
  width: 100px;
}

.text-primary {
  min-height: 50px;
}

.know-us-position {
  margin-top: 0px;
}

.know-us-title {
  margin-top: 0px;
}

.know-us-description {
  margin-bottom: 0px;
}

.know-us-image {
  margin-top: 20px;
  width: 130px !important;
}

.d-block{
  object-fit: cover;
  object-position:bottom;
}

.h1-seo{
  font-size: 4rem !important;
}

.nav-link--styles{
  display: flex;
justify-content: center;
align-items: center;
  color: #231f20 !important;
  font-weight: 600 !important;
  text-decoration: none;
  height: auto;
  gap: 4px;
}

.nav-link--styles *{
  display: flex;
  justify-content:center;
  align-items: center;
  margin: 0;
}

@media (min-width:800px) {
  .h3-phrase {
    padding-left: 50%;
  }
}
</style>
