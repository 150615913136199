<template>
  <navbar position="fixed" type="info" :transparent="transparent" :color-on-scroll="colorOnScroll" menu-classes="ml-auto">
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="../../public/img/logo.png" alt="">
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a class="nav-link" href="#">
          <!--INICIO -->
          <p>Inicio</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#QuienesSomos">
          <!--  NOSOTROS -->
          <p>Nosotros</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#Proyectos">
          <!--PROYECTOS-->
          <p>Proyectos</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#Contactenos">
          <!--  CONTACTENOS -->
          <p>Contactenos</p>
        </a>
      </li>

        <li class="nav-item">
          <a class="nav-link" rel="tooltip" title="Follow us on Instagram" data-placement="bottom"
            href="https://www.instagram.com/alianzamarytierra/" target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
        </li>
    



    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    /*    DropDown, */
    Navbar,
    /* NavLink, */
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
